import * as React from 'react'
import { Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faInstagram, faGithub, faDiscord, faYoutube } from '@fortawesome/free-brands-svg-icons'

import { footer, fontSize22 } from './footer.module.css'


const MainFooter = () => {
    return (

        <footer className={`text-muted jumbotron-fluid page-footer footer ${footer}`}>

            <Container>

                <div className={`row pt-5 pb-4`}>

                    <div className={`col-md-6 col-xs-12 text-center text-md-start pb-4`}>
                        <p className={`font-weight-bold text-muted mb-2 ${fontSize22}`}>ai03 Design Studio</p>
                        <small className={`text-muted`}>The pursuit of the best input.</small>

                    </div>


                    <div className={`col-md-6 col-xs-12 text-center text-md-end`}>
                        <p className={fontSize22} >
                            <a className="text-muted" href="https://twitter.com/ai03_2725">
                                <FontAwesomeIcon icon={faTwitter} />
                            </a>
                            <a className="text-muted ms-3" href="https://www.instagram.com/ai03_2725/">
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                            <a className="text-muted ms-3" href="https://github.com/ai03-2725/">
                                <FontAwesomeIcon icon={faGithub} />
                            </a>
                            <a className="text-muted ms-3" href="https://discord.gg/qA5JD9y">
                                <FontAwesomeIcon icon={faDiscord} />
                            </a>
                            <a className="text-muted ms-3" href="https://youtube.com/channel/UC2FbYfD2M7kFdmF3-dkGBRA">
                                <FontAwesomeIcon icon={faYoutube} />
                            </a>


                        </p >

                    </div >

                </div >
            </Container >
        </footer >
    )
}

export default MainFooter

