import * as React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { navbarDropdown } from './navbar.module.css'

const MainNavbar = ({ transparentNavbar }) => {

    const data = useStaticQuery(graphql`
    query {
        strapiAssets {
          navbaricon {
            localFile {
              childImageSharp {
                gatsbyImageData(
                    width: 30
                    quality: 100
                )
              }
            }
          }
        }
      }
      
    `)

    return (

        <Navbar expand="md" variant="dark" bg="primary" fixed="top" className={"" + (transparentNavbar ? 'bg-opacity-75' : '')}>
            <Container>
                <Navbar.Brand as={Link} to="/" className="nav-link" activeClassName="active"> <GatsbyImage image={getImage(data.strapiAssets.navbaricon.localFile)} alt="ai03 Design Studio" loading="eager" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarResponsive" />
                <Navbar.Collapse id="navbarResponsive" className="justify-content-end">
                    <Nav as="ul">
                        <Nav.Item as="li" className="w-100 text-md-start text-center">
                            <Link to="/categories" className="nav-link" activeClassName="active">Projects</Link>
                        </Nav.Item>
                        <Nav.Item as="li" className="w-100 text-md-start text-center">
                            <Link to="/news" className="nav-link" activeClassName="active">News</Link>
                        </Nav.Item>
                        <Nav.Item as="li" className="w-100 text-md-start text-center">
                            <Link to="/status" className="nav-link" activeClassName="active">Status</Link>
                        </Nav.Item>
                        {/* <Nav.Item as="li">
                            <a href="https://info.ai03.com/" className="nav-link">Info</a>
                        </Nav.Item> */}
                        <Nav.Item as="li" className="w-100 text-md-start text-center">
                            <a href="https://discord.gg/qA5JD9y" className="nav-link">Discord</a>
                        </Nav.Item>
                        {/* <Nav.Item as="li">
                            <Link to="/resources" className="nav-link" activeClassName="active">Resources</Link>
                        </Nav.Item> */}
                        <NavDropdown title="Other" id="nav-dropdown" menuVariant="dark" align="end" className={`${navbarDropdown} w-100 text-md-start text-center`}>
                            <NavDropdown.Item className="text-md-start text-center" as={Link} to="/files" role="button">Files and Resources</NavDropdown.Item>
                            <NavDropdown.Item className="text-md-start text-center" as="a" href="https://info.ai03.com/shelves/build-guides" role="button">Build Guides</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item className="text-md-start text-center" as="a" href="https://info.ai03.com/books/how-to-keyboard" role="button">Keyboard Newcomer Guide</NavDropdown.Item>
                            <NavDropdown.Item className="text-md-start text-center" as="a" href="https://info.ai03.com/books/general-info/page/switch-choice-flowchart" role="button">Switch Choice Flowchart</NavDropdown.Item>
                            <NavDropdown.Item className="text-md-start text-center" as={Link} to="/designer-resources" role="button">Designer Resources</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item className="text-md-start text-center" as={Link} to="/commissions" role="button">Commissions</NavDropdown.Item>
                            <NavDropdown.Item className="text-md-start text-center" as="a" href="https://docs.google.com/forms/d/e/1FAIpQLSeDXYrYGG9i69_cJkETaZZj6OcjNraM9qaULQIhStfAcz5hfQ/viewform?usp=sf_link" role="button">Contact</NavDropdown.Item>
                            <NavDropdown.Item className="text-md-start text-center" as={Link} to="/about" role="button">About</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default MainNavbar

