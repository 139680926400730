import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import MainNavbar from './navbar.js'
import MainFooter from './footer.js'

import 'bootswatch/dist/lux/bootstrap.min.css';
import { mainContainer } from './main-layout.module.css'


const MainLayout = ({ pageTitle, children, transparentNavbar }) => {

    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageTitle} - {data.site.siteMetadata.title}</title>
            </Helmet>

            <MainNavbar transparentNavbar={transparentNavbar} />
            <main className={"" + (transparentNavbar ? '' : mainContainer)}>
                {children}
            </main>
            {transparentNavbar
            ?
            <div/>
            :
            <MainFooter />
            }
            
        </div>

    )
}
export default MainLayout